import BasePlugin from '../BasePlugin'

export default class UnloadInvestmentBudgetReportQuery extends BasePlugin {
  async execute () {
    this.context.$loading()
    let me = this
    let scenarioId = this.context.getModel().scenario
    let address = `${this.context.$config.api}/etleditor/export/68?scen_id=${scenarioId}`
    this.context.$http({
      method: 'post',
      url: address,
      responseType: 'arraybuffer'
    }).then((response2) => {
      this.openExportAttachment(response2.data, me, 'xlsm')
      this.context.$loading().close()
    })
  }
}
